<template>
  <div class="col-12 col-lg-6 c">
    <div class="card card-body">
        <div class="alert alert-info g">
            <span class="text-dark">
                <strong>تعريف بالخاصية:</strong>
                <br>
                سوف يظهر تنبيه في التقارير اليومية اذا تخطى الطالب عدد ايام غياب معينة.</span>
        </div>
        <br>
        <b-form-checkbox
            :unchecked-value="false"
            v-model="enabled"
            :value="true"
        >
            تفعيل الخاصية
        </b-form-checkbox>
        <br>
        <div class="card-footer" v-if="enabled">
            <div class="form-group">
              <label for="">تاريخ بداية احتساب الغياب</label>
              <input type="date"
                class="form-control" v-model="from">
              <small id="helpId" class="form-text text-muted">التاريخ الذي سوف يبدأ النظام باحتساب الغياب منه. مثل بداية الفصل الدراسي الخ...</small>
            </div>
            <div class="form-group">
              <label for="">عدد ايام الغياب</label>
              <input type="number"
                class="form-control" v-model="days">
              <small id="helpId" class="form-text text-muted">التي اذا تجاوزها الطالب سوف يظهر تنبيه في التقارير اليومية.</small>
            </div>
        </div>
        <div class="col-12 text-center g">
            <button class="btn btn-primary" @click="save()">
                <i class="fa fa-save"></i>
                حفظ الخيارات
            </button>
        </div>
    </div>
  </div>
</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            enabled: false,
            days: 3,
            from: new Date().toISOString().split("T")[0]
        }
    },
    created(){
        if(!checkPer("students|settings")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        $.post(api + '/user/students/ghiab-alerts', {
            jwt: this.user.jwt
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response, 200)
            }else{
                if(r.response.from){
                    g.enabled = r.response.enabled;
                    g.days = r.response.days,
                    g.from = r.response.from
                }
            }
        }).fail(function(){
            alert("حدث خطأ في الاتصال", 200)
        })
    },
    methods: {
        save(){
            var g = this;
            $.post(api + '/user/students/ghiab-alerts-save', {
                jwt: this.user.jwt,
                enabled: g.enabled?.toString(),
                days: g.days,
                from: g.from
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    alert("تم الحفظ", 100)
                }
            }).fail(function(){
                alert("حدث خطأ في الاتصال", 200)
            })
        }
    }
}
</script>

<style>

</style>